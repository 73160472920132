.clients{
	padding-top: 30px;
	padding-bottom: 30px;
	@include md{
		padding-left: 35px;
		padding-right: 35px;
	}
	@include xs{
		padding-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 20px;
	}
	.items{
		display: flex;
		flex-wrap: wrap;
		width: 677px;
		margin: auto;
		margin-top: 43px;
		max-width: 100%;
		@include xs{
			width: 100%;
			max-width: 100%;
			display: block;
			margin-top: 25px;
		}
	}
	.item{
		width: calc((100% - (30px * 3)) / 4);
		margin-bottom: 55px;
		@include xs{
			margin: auto;
			width: auto;
		}
		&:not(:nth-of-type(4n)){
			margin-right: 30px;
			@include xs{
				margin: auto;
			}
		}
		img{
			width: 100%;
		}
	}
	.controls{
		display: none;
		@include xs{
			display: flex;
			margin-top: 19px;
			justify-content: space-between;
		}
	}
	.swiper-pagination{
		@include xs{
			width: 49%!important;
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.swiper-pagination-bullet{
				width: 3px;
				height: 3px;
				border-radius: 0;
			}
		}
	}
	.buttons{
		@include xs{
			width: 48%;
			display: flex;
			position: relative;
			&:after{
				content: '';
				height: 2px;
				background: #B6B6B6;
				width: 100%;
				z-index: 0;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
			.next-slide{
				width: 100%;
				height: 16px;
				background-repeat: no-repeat;
				z-index: 1;
				background-position: right center;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='108' height='16' viewBox='0 0 108 16' fill='none'%3E %3Cpath d='M107.707 8.70711C108.098 8.31658 108.098 7.68342 107.707 7.29289L101.343 0.928932C100.953 0.538408 100.319 0.538408 99.9289 0.928932C99.5384 1.31946 99.5384 1.95262 99.9289 2.34315L105.586 8L99.9289 13.6569C99.5384 14.0474 99.5384 14.6805 99.9289 15.0711C100.319 15.4616 100.953 15.4616 101.343 15.0711L107.707 8.70711ZM0 9H107V7H0V9Z' fill='%23B6B6B6'/%3E %3C/svg%3E");
			}
			.prev-slide{
				width: 50%;
				height: 16px;
				transform: rotate(180deg);
				position: absolute;
				left: 0;
				top: 0;
				z-index: 2;
				background-repeat: no-repeat;
				background-position: right center;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='108' height='16' viewBox='0 0 108 16' fill='none'%3E %3Cpath d='M107.707 8.70711C108.098 8.31658 108.098 7.68342 107.707 7.29289L101.343 0.928932C100.953 0.538408 100.319 0.538408 99.9289 0.928932C99.5384 1.31946 99.5384 1.95262 99.9289 2.34315L105.586 8L99.9289 13.6569C99.5384 14.0474 99.5384 14.6805 99.9289 15.0711C100.319 15.4616 100.953 15.4616 101.343 15.0711L107.707 8.70711ZM0 9H107V7H0V9Z' fill='%23B6B6B6'/%3E %3C/svg%3E");
				&.swiper-button-disabled{
					display: none;
				}
			}
		}
	}
}