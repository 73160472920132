.comfortably{
	padding-top: 30px;
	padding-bottom: 30px;
	@include md{
		padding-top: 30px;
		padding-left: 35px;
		padding-right: 35px;
	}
	@include xs{
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 15px;
		padding-bottom: 12px;
	}
	.items{
		display: flex;
		justify-content: space-around;
		margin-top: 48px;
		@include sm{
			flex-wrap: wrap;
			margin-top: 27px;
		}
	}
	.item{
		width: 194px;
		max-width: 25%;
		position: relative;
		padding-bottom: 30px;
		@include sm{
			// width: 50%;
			max-width: 50%;
			padding-bottom: 20px;
		}
		&:after{
			content: '';
			position: absolute;
			bottom: 0;
			width: 194px;
			height: 4px;
			background: #4438B4;
			left: 50%;
			transform: translateX(-50%);
			max-width: 97%;
			@include sm{
				content: none;
			}
		}
	}
	.image{
		text-align: center;
		svg{
			width: 94px;
			height: 94px;
		}
	}
	.name{
		font-weight: bold;
		font-size: 24px;
		line-height: 29px;
		text-align: center;
		color: #4F4F4F;
		margin-top: 14px;
		@include lg{
			font-size: 20px;
			line-height: 25px;
		}
		@include md{
			font-weight: normal;
			font-size: 24px;
			line-height: 29px;
		}
	}
}