.connect{
	background-image: url(../img/old/bg3.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	border-radius: 3px;
	padding-left: 81px;
	padding-right: 81px;
	padding-top: 92px;
	padding-bottom: 124px;
	max-width: 1083px;
	margin: auto;
	margin-top: 33px;
	margin-bottom: 30px;
	@include lg{
		padding: 14px;
	}
	@include xs{
		margin-bottom: 15px;
		padding: 10px;
		padding-bottom: 14px;
	}
	.choise_text{
		text-align: center;
		font-weight: bold;
		font-size: 18px;
		line-height: 22px;
		color: #FFFFFF;
		@include xs{
			font-size: 19px;
			line-height: 23px;
		}
	}
	.title-white{
		text-align: center;
		@include xs{
			text-align: left;
		}
	}
	.description-common{
		text-align: center;
		font-size: 29px;
		margin-top: 20px;
		@include lg{
			font-size: 20px;
			line-height: 25px;
		}
		@include md{
			font-size: 29px;
			line-height: 20px;
		}
		@include sm{
			line-height: 1;
		}
		@include xs{
			font-size: 24px;
			line-height: 29px;
			text-align: left;
		}
	}
	.checkboxes{
		display: flex;
		justify-content: center;
		margin-top: 23px;
		@include sm{
			flex-direction: column;
		}
	}
	.checkbox-common{
		&:not(:last-child){
			margin-right: 42px;
			@include sm{
				margin-right: 0;
				margin-bottom: 14px;
			}
		}
		&-text{
			color: #FFFFFF;
		}
	}
	.form{
		padding: 29px 65px 24px;
		background: rgba(#FFFFFF, 0.2);
		border-radius: 15px;
		margin-top: 20px;
		@include lg{
			padding: 17px;
		}
		@include xs{
			margin-top: 24px;
		}
		&.text{
			.input[data-for-option=text]{
				opacity: 1;
				pointer-events: all;
			}
		}
		&.voice{
			.input[data-for-option=voice]{
				opacity: 1;
				pointer-events: all;
			}
		}
		&.social{
			.input[data-for-option=social]{
				opacity: 1;
				pointer-events: all;
			}
		}
	}
	.inputs{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 22px;
		@include xs{
			padding: 14px;
			margin-top: 3px;
		}
	}
	.input{
		width: calc(((100% - (20px * 2)) / 3));
		background: #FFFFFF;
		margin-bottom: 16px;
		position: relative;
		@include sm{
			width: calc(((100% - (20px * 1)) / 2));
		}
		@include xs{
			width: 100%;
			margin-bottom: 10px;
		}
		&[data-for-option]{
			opacity: .2;
			pointer-events: none;
			@include xs{
				opacity: .5;
			}
		}
		input{
			width: 100%;
			height: 100%;
			border: none;
			background: transparent;
			padding-top: 29px;
			padding-bottom: 14px;
			padding-left: 13px;
			padding-right: 13px;
			font-size: 18px;
			line-height: 21px;
			&::placeholder{
				color: #BDBDBD;
			}
		}
		.placeholder{
			position: absolute;
			top: 5px;
			left: 13px;
			pointer-events: none;
		}
	}
	.button{
		margin-top: 23px;
		padding-left: 73px;
		padding-right: 73px;
		@include lg{
			margin-top: 15px;
		}
		@include xs{
			padding-left: 10px;
			padding-right: 10px;
			margin-top: 0;
		}
	}
}