.howork{
	padding-top: 30px;
	padding-bottom: 30px;
	@include md{
		padding-left: 35px;
		padding-right: 35px;
	}
	@include sm{
		padding-left: 10px;
		padding-right: 10px;
	}
	@include xs{
		padding-top: 3px;
		padding-bottom: 0;
	}
	.title-common{
		@include xs{
			width: 267px;
		}
	}
	.items{
		display: flex;
		justify-content: space-between;
		margin-top: 43px;
		@include xs{
			flex-direction: column;
			margin-top: 25px;
		}
	}
	.item{
		width: calc(50% - 10px);
		font-size: 24px;
		line-height: 29px;
		color: #828282;
		@include lg{
			font-size: 19px;
			line-height: 23px;
		}
		@include md{
			font-size: 24px;
			line-height: 29px;
		}
		@include xs{
			width: 100%;
			margin-bottom: 19px;
		}
	}
}