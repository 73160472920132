.footer{
	background: #005BD1;
	padding-top: 42px;
	padding-bottom: 42px;
	margin-top: -1px;
	z-index: 1;
	position: relative;
	font-style: normal;
	font-weight: normal;
	font-size: 23px;
	line-height: 27px;
	color: #FFFFFF;
	@include lg{
		font-size: 20px;
		line-height: 25px;
	}
	@include md{
		font-size: 23px;
		line-height: 27px;
		padding-left: 35px;
		padding-right: 35px;
		text-align: center;
	}
	@include xs{
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 28px;
		padding-bottom: 29px;
		text-align: left;
	}
	.container{
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include md{
			flex-direction: column;
		}
		@include xs{
			align-items: flex-start;
		}
	}
	.cards{
		@include md{
			margin-top: 28px;
		}
		svg{
			&:first-child{
				margin-bottom: 10px;
				margin-right: 45px;
			}
		}
	}
	.col{
		@include md{
			&:nth-of-type(1){
				
			}
			&:nth-of-type(2){
				margin-top: 26px;
				display: flex;
				flex-direction: column-reverse;
			}
			&:nth-of-type(3){
				margin-top: 23px;
				width: 100%;
			}
		}
	}
}
.gmail{
	.footer{
		.container{
			max-width: none;
		}
	}
}
.outlook{
	.footer{
		.container{
			max-width: none;
		}
	}
}