.socials{
	display: flex;
	padding-top: 30px;
	padding-bottom: 90px;
	@include lg{
		flex-direction: column;
		padding-top: 0;
	}
	@include md{
		padding-left: 35px;
		padding-right: 35px;
	}
	@include xs{
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 15px;
		padding-bottom: 25px;
	}
	svg{
		max-width: 32px;
		max-height: 32px;
		// @include xs{
		// 	max-height: 32px;
		// 	max-width: none;
		// }
	}
	.items{
		@include lg{
			margin-top: 15px;
		}
	}
	&-title{
		font-weight: 500;
		font-size: 23px;
		line-height: 28px;
		color: #333333;
		margin-right: 29px;
		@include xs{
			font-size: 24px;
			font-weight: 400;
		}
	}
	.item{
		&:not(:last-child){
			margin-right: 39px;
			@include xs{
				margin-right: 27px;
			}
		}
		&:first-child{
			svg{
				max-width: 44px;
			}
		}
	}
}