.banner{
	width: 100%;
	background-image: url(../img/old/bg1.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	// height: 600px;
	margin-top: 3px;
	margin-bottom: 6px;
	border-radius: 4px;
	padding: 63px 90px 120px;
	@include lg{
		padding: 30px 30px 30px;
	}
	@include md{
		margin-top: 0;
		border-radius: 0;
		background-position: left -3px;
		padding: 45px 35px 45px;
		margin-bottom: 0;
	}
	@include sm{
		padding: 25px 10px 25px;
	}
	.line{
		width: 272px;
		height: 3px;
		background: #FFFFFF;
		margin-top: 0;
	}
	.pretitle-common{
		margin-top: 24px;
		@include lg{
			margin-top: 15px;
		}
		@include md{
			margin-top: 24px;
		}
	}
	.title-main{
		margin-top: 88px;
		margin-bottom: 99px;
		@include lg{
			margin-top: 25px;
			margin-bottom: 35px;
		}
		@include md{
			margin-top: 30px;
			margin-bottom: 50px;
			padding-right: 35px;
		}
	}
	.button-link{
		margin-left: 35px;
		@include xs{
			display: block;
			margin: auto;
			margin-top: 20px;
			text-align: center;
		}
	}
}