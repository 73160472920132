.works{
	padding-top: 60px;
	padding-bottom: 30px;
	@include md{
		padding-top: 30px;
		padding-left: 35px;
		padding-right: 35px;
	}
	@include xs{
		padding-top: 25px;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 15px;
	}
	.items{
		width: calc(100% - 140px);
		margin: auto;
		margin-top: 43px;
		position: relative;
		@include xs{
			margin-top: 25px;
			width: 100%;
		}
	}
	.item{
		border-radius: 4px;
		overflow: hidden;
		picture{
			width: 100%;
		}
		img{
			width: 100%;
		}
	}
	.controls{
		@include xs{
			position: absolute;
			bottom: 45px;
			left: 0;
			background: rgba(#E1E1E1, 0.5);
			border-radius: 87px;
			width: 100%;
			height: 61px;
			z-index: 1;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-left: 23px;
			padding-right: 23px;
		}
	}
	.swiper-button{
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='50' viewBox='0 0 29 50' fill='none'%3E %3Cpath d='M3 3L25 25L3 47' stroke='%23005BD1' stroke-width='5' stroke-linecap='round'/%3E %3C/svg%3E");
		width: 22px;
		height: 44px;
		background-size: 22px 44px;
		@include xs{
			margin-top: 0;
		}
	}
	.swiper-button-prev{
		left: auto;
		right: calc(100% + 30px);
		transform: rotate(180deg);
		@include xs{
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			position: relative;
		}
	}
	.swiper-button-next{
		right: auto;
		left: calc(100% + 30px);
		@include xs{
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			position: relative;
		}
	}
}