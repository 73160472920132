.manager{
	background-image: url(../img/old/bg2.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	border-radius: 4px;
	padding: 95px;
	display: flex;
	position: relative;
	@include lg{
		padding: 35px;
	}
	@include md{
		padding-left: 35px;
		padding-right: 35px;
		background-position: left center;
	}
	@include xs{
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 26px;
		padding-bottom: 45px;
	}
	.title-white{
		@include xs{
			br{
				display: none;
			}
		}
	}
	.description-common{
		margin-top: 21px;
	}
	.button{
		margin-top: 67px;
		@include lg{
			margin-top: 35px;
		}
		@include xs{
			padding-left: 35px;
			padding-right: 35px;
		}
	}
	.left{
		width: 60%;
		@include md{
			width: 70%;
		}
		@include xs{
			width: 100%;
		}
	}
	.right{
		position: absolute;
		right: 0;
		width: 47%;
		top: 50%;
		transform: translateY(-50%);
		@include md{
			display: none;
		}
		img{
			@include lg{
				width: 100%;
			}
		}
	}
}