.rates{
	padding-top: 30px;
	padding-bottom: 30px;
	@include md{
		padding-left: 35px;
		padding-right: 35px;
	}
	@include xs{
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 15px;
	}
	.calc{
		margin-top: 43px;
		border: 1px solid #E0E0E0;
		border-radius: 15px;
		padding-bottom: 15px;
		@include xs{
			margin-top: 25px;
		}
	}
	.row{
		padding: 24px 31px;
		position: relative;
		@include md{
			padding-bottom: 16px;
		}
		@include xs{
			padding-top: 13px;
			padding-bottom: 13px;
			padding-left: 10px;
			padding-right: 10px;
		}
		&:nth-of-type(2){
			background: #F4F4F4;
		}
		&.lined{
			&:after{
				content: '';
				width: 1005px;
				max-width: 97%;
				height: 1px;
				background: #E0E0E0;
				margin: auto;
				display: block;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
			}
			}
	}
	.title{
		font-weight: bold;
		font-size: 18px;
		line-height: 22px;
		color: #4F4F4F;
		@include xs{
			text-align: center;
			font-weight: 400;
			line-height: 21px;
		}
	}
	.name{
		font-size: 24px;
		line-height: 29px;
		color: #000000;
	}
	.options{
		display: flex;
		margin-top: 25px;
		align-items: flex-end;
		@include md{
			flex-wrap: wrap;
		}
		&-checkbox{
			display: flex;
			flex-direction: column;
		}
	}
	.checkbox-common{
		min-width: 200px;
		&:not(:last-child){
			margin-bottom: 20px;
		}
	}
	.radio-common{
		min-width: 200px;
		@include md{
			margin-bottom: 15px;
		}
		@include xs{
			flex-wrap: wrap;
			width: 100%;
		}
		&:not(:last-child){
			margin-right: 20px;
		}
		&-false{
			@include xs{
				width: 100%;
				margin-bottom: 9px;
			}
		}
		input[type=radio]{
			&:checked{
				& + .radio-common-text{
					& + .count{
						input{
							pointer-events: all;		
							color: #5C7BF7;
						}
						.minus{
							pointer-events: all;
							background: #5C7BF7;
							border-right: 2px solid #536FDF;
						}
						.plus{
							pointer-events: all;
							background: #5C7BF7;
						}
					}
				}
			}
		}
	}
	.count{
		display: flex;
		align-items: center;
		margin-left: 17px;
		@include xs{
			margin-left: 51px;
		}
		input{
			font-family: Cabin;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 19px;			
			color: #828282;
			background: none;
			width: auto;
			border: none;
			padding: 0;
			font-size: 16px;
			line-height: 1;
			pointer-events: none;
		}
		.controls{
			display: flex;
			margin-left: 12px;
			margin-right: 18px;
		}
		.minus{
			width: 28px;
			height: 26px;
			background: #828282;
			border-radius: 6px 0 0 6px;
			border: none;
			position: relative;
			border-right: 2px solid darken(#828282, 10);
			pointer-events: none;
			&:after{
				content: '';
				width: 10px;
				height: 2px;
				background: #FFFFFF;
				border-radius: 1px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.plus{
			width: 26px;
			height: 26px;
			background: #828282;
			border-radius: 0 6px 6px 0;
			border: none;
			position: relative;
			pointer-events: none;
			&:after{
				content: '';
				width: 10px;
				height: 2px;
				background: #FFFFFF;
				border-radius: 1px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			&:before{
				content: '';
				width: 2px;
				height: 10px;
				background: #FFFFFF;
				border-radius: 1px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
	.pb-hide{
		padding-bottom: 0;
		@include sm{
			padding-bottom: 15px;
		}
	}
	.result{
		padding-top: 0;
		padding-bottom: 0;
		position: relative;
		&-for-fixed{
			padding-top: 12px;
			padding-bottom: 12px;
			&.fixed{
				padding-left: 10px;
				padding-right: 10px;
				@include xs{
					border: 2px solid #5C7BF7;
					border-radius: 4px;
					position: fixed;
					bottom: 0;
					width: 100%;
					z-index: 2;
					left: 0;
					background: #fff;
					.mobile_arrow{
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'%3E %3Cpath d='M21.5625 12.4999L20.0969 12.4999C19.7781 12.4999 19.475 12.6531 19.2875 12.9124L16 17.4562L12.7125 12.9124C12.525 12.653 12.225 12.4999 11.9031 12.4999L10.4375 12.4999C10.2344 12.4999 10.1156 12.7312 10.2344 12.8968L15.7969 20.5843C15.8199 20.6162 15.8502 20.6423 15.8852 20.6602C15.9202 20.6781 15.9591 20.6875 15.9984 20.6875C16.0378 20.6875 16.0766 20.6781 16.1117 20.6602C16.1467 20.6423 16.177 20.6162 16.2 20.5843L21.7625 12.8968C21.7899 12.8598 21.8066 12.8158 21.8105 12.7699C21.8144 12.724 21.8055 12.6779 21.7848 12.6367C21.764 12.5956 21.7323 12.561 21.693 12.5368C21.6538 12.5127 21.6086 12.4999 21.5625 12.4999Z' fill='%23828282'/%3E %3Cpath d='M4.5 28.5L27.5 28.5C28.0531 28.5 28.5 28.0531 28.5 27.5L28.5 4.5C28.5 3.94687 28.0531 3.5 27.5 3.5L4.5 3.5C3.94688 3.5 3.5 3.94687 3.5 4.5L3.5 27.5C3.5 28.0531 3.94687 28.5 4.5 28.5ZM5.75 5.75L26.25 5.75L26.25 26.25L5.75 26.25L5.75 5.75Z' fill='%23828282'/%3E %3C/svg%3E");
						width: 32px;
						height: 32px;
						position: absolute;
						z-index: 3;
						right: 7px;
						top: 12px;
						transform: rotate(180deg);
					}
				}
			}
		}
	}
	.maximal{
		.result{
			&-for-fixed{
				&.fixed{
					@include xs{
						.mobile_arrow{
							transform: rotate(0);
						}
					}
				}
			}
		}
	}
	.price{
		font-size: 24px;
		line-height: 29px;
		color: #828282;
		text-align: right;
		@include xs{
			text-align: left;
		}
		// .value{
		// 	@include xs{
		// 		display: none;
		// 	}
		// }
	}
	.fixed{
		.price{
			.value{
				@include xs{
					display: none;
				}
			}
		}
	}
	.maximal{
		.price{
			.value{
				@include xs{
					display: block;
				}
			}
		}
	}
	.discount{
		font-size: 36px;
		line-height: 43px;
		color: #333333;
		text-align: right;
		@include sm{
			font-size: 30px;
			line-height: 37px;
		}
		@include xs{
			text-align: left;
			font-weight: bold;
			margin-top: 10px;
		}
		.value{
			font-weight: bold;
		}
	}
	.fixed{
		.discount{
			@include xs{
				display: none;
			}
		}
	}
	.maximal{
		.discount{
			@include xs{
				display: block;
				margin-top: 6px;
			}
		}
	}
	.button{
		@include xs{
				padding-left: 3px;
				padding-right: 3px;
		}
		.hide-mob{
			@include xs{
				display: none;
			}
		}
	}
}