#modals{
    display: none;
}
.success{
    text-align: center;
    padding: 44px 10px;
    background: #fff;
    margin: 0;
    max-width: 100%;
    overflow: auto;
    position: relative;
    vertical-align: middle;
    display: block;
	margin: auto;
    width: 550px;
    background-color: #fff;
	background-image: url(../img/old/bg3.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
    @include md{
        padding: 30px 20px;
    }
    .title{
        font-size: 2.4rem;
        margin-bottom: 2rem;
        color: #000;
        font-size: 28px;
        font-weight: 900;
        letter-spacing: 4.67px;
		color: #fff;
        @include xs{
            font-size: 20px;
        }
    }
    .desc{
        font-size: 1.4rem;
    }
    .mfp-close{
        color: #fff;
    }
}
.modal_connect{
	background-image: url(../img/old/bg3.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	border-radius: 3px;
	padding-left: 81px;
	padding-right: 81px;
	padding-top: 92px;
	padding-bottom: 124px;
	max-width: 1083px;
	margin: auto;
    position: relative;
	@include lg{
		padding: 14px;
	}
	@include xs{
		padding: 10px;
	}
	.choise_text{
		text-align: center;
		font-weight: bold;
		font-size: 18px;
		line-height: 22px;
		color: #FFFFFF;
		@include xs{
			font-size: 19px;
			line-height: 23px;
			text-transform: uppercase;
		}
	}
	.title-white{
		text-align: center;
		@include sm{
			display: none;
		}
		@include xs{
			text-align: left;
		}
	}
	.description-common{
		text-align: center;
		font-size: 29px;
		margin-top: 20px;
		@include lg{
			font-size: 20px;
			line-height: 25px;
		}
		@include md{
			font-size: 29px;
			line-height: 20px;
		}
		@include sm{
			display: none;
			line-height: 1;
		}
		@include xs{
			font-size: 24px;
			line-height: 29px;
			text-align: left;
		}
	}
	.checkboxes{
		display: flex;
		justify-content: center;
		margin-top: 23px;
		@include md{
			justify-content: space-around;
		}
	}
	.checkbox-common{
		&-false{
			@include md{
				padding-left: 0;
				padding-bottom: 27px;
			}
			&:before{
				@include md{

				}
			}
			&:after{
				@include md{
				}
			}
		}
		&:not(:last-child){
			margin-right: 42px;
			@include sm{
				margin-right: 0;
			}
		}
		@include xs{
			&:nth-of-type(1){
				.checkbox-common-false{
					&:before{
						left: 7px;
					}
					&:after{
						left: 11px;
					}
				}
				input[type=checkbox]:checked+.checkbox-common-text:after{
					left: 35px;
				}
			}
			&:nth-of-type(2){
				.checkbox-common-false{
					&:before{
						left: 12px;
					}
					&:after{
						left: 15px;
					}
				}
				input[type=checkbox]:checked+.checkbox-common-text:after{
					left: 41px;
				}
			}
			&:nth-of-type(3){
				.checkbox-common-false{
					&:before{
						left: 24px;
					}
					&:after{
						left: 27px;
					}
				}
				input[type=checkbox]:checked+.checkbox-common-text:after{
					left: 53px;
				}
			}
		}
		&-text{
			color: #FFFFFF;
			.mobile{
				display: none;
			}
			.mobile{
				@include xs{
					display: block;
				}
			}
			.desktop{
				@include xs{
					display: none;
				}
			}
			@include md{
				font-size: 18px;
				line-height: 21px;
				font-weight: 400;
			}
		}
	}
	.form{
		padding: 29px 65px 24px;
		background: rgba(#FFFFFF, 0.2);
		border-radius: 15px;
		margin-top: 20px;
		@include lg{
			padding: 17px;
		}
		@include sm{
			margin-top: 0;
			padding: 10px;
		}
		&.text{
			.input[data-option=text]{
				opacity: 1;
				pointer-events: all;
			}
		}
		&.voice{
			.input[data-option=voice]{
				opacity: 1;
				pointer-events: all;
			}
		}
		&.social{
			.input[data-option=social]{
				opacity: 1;
				pointer-events: all;
			}
		}
	}
	.inputs{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 22px;
		@include xs{
			padding: 14px;
			margin-top: 3px;
		}
	}
	.input{
		width: calc(((100% - (20px * 2)) / 3));
		background: #FFFFFF;
		margin-bottom: 16px;
		position: relative;
		@include sm{
			width: calc(((100% - (20px * 1)) / 2));
		}
		@include xs{
			width: 100%;
			margin-bottom: 10px;
		}
		&[data-option]{
			opacity: .2;
			pointer-events: none;
			@include xs{
				opacity: .5;
			}
		}
		input{
			width: 100%;
			height: 100%;
			border: none;
			background: transparent;
			padding-top: 29px;
			padding-bottom: 14px;
			padding-left: 13px;
			padding-right: 13px;
			font-size: 18px;
			line-height: 21px;
			@include xs{
				padding: 9px 13px;
			}
			&::placeholder{
				color: #BDBDBD;
				@include xs{
					opacity: 0;
				}
			}
			@include xs{
				&:not([value='']), &:focus{
					&+.placeholder{
						&+.placeholder-mobile{
							display: none;
						}
					}
				}
			}
		}
		.placeholder{
			position: absolute;
			top: 5px;
			left: 13px;
			pointer-events: none;
			@include xs{
				display: none;
				top: 50%;
				transform: translateY(-50%);
				color: #BDBDBD;
			}
		}
		.placeholder-mobile{
			display: none;
			position: absolute;
			top: 5px;
			left: 13px;
			pointer-events: none;
			@include xs{
				display: block;
				top: 50%;
				transform: translateY(-50%);
				color: #BDBDBD;
			}
		}
	}
	.button{
		margin-top: 23px;
		padding-left: 73px;
		padding-right: 73px;
		@include lg{
			margin-top: 15px;
		}
		@include xs{
			padding-left: 10px;
			padding-right: 10px;
			margin-top: 0;
			padding-top: 11px;
			padding-bottom: 11px;

		}
	}
    .mfp-close{
        color: #fff;
		@include xs{
			right: -7px;
			top: -7px;
		}
    }
}