.actions{
	display: flex;
	justify-content: space-around;
	padding-top: 30px;
	padding-bottom: 30px;
	@include lg{
		flex-wrap: wrap;
		padding-bottom: 0;
	}
	@include xs{
		padding: 43px 54px 3px;
	}
	.item{
		// width: 25%;
		text-align: center;
		@include lg{
			width: 50%;
			margin-bottom: 50px;
		}
		@include xs{
			width: auto;
			text-align: left;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-bottom: 32px;
			&:nth-of-type(1){
				.name{
					margin-right: 5px;
				}
			}
			&:nth-of-type(2){
				.name{
					width: 50%;
					margin-right: 43px;
				}
			}
			&:nth-of-type(3){
				.name{
					width: 50%;
					margin-right: 47px;
				}
			}
		}
		@include xs{
			width: 100%;
		}
	}
	.name{
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		line-height: 29px;
		text-align: center;
		color: #828282;
		@include xs{
			text-align: left;
			margin-right: 20px;
			margin-bottom: 18px;
		}
	}
	.image{
		margin-top: 15px;
		@include xs{
			margin-top: 0;
		}
	}
}