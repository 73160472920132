.subscribe{
	padding-top: 80px;
	padding-bottom: 80px;
	@include md{
		padding-left: 35px;
		padding-right: 35px;
	}
	@include xs{
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 12px;
		padding-bottom: 15px;
	}
	.form{
		display: flex;
		border: 1px solid #BDBDBD;
		border-radius: 15px 100px 100px 15px;
		// align-items: center;
		@include lg{
			border-radius: 0;
			border: none;
			// flex-direction: column;
			flex-wrap: wrap;
		}
		@include sm{
			flex-direction: column;
			align-items: center;
		}
		@include xs{
			align-items: flex-start;
		}
	}
	&-title{
		font-weight: 500;
		font-size: 23px;
		line-height: 28px;
		color: #333333;
		width: 38%;
		padding: 28px;
		@include xxl{
			width: 45%;
		}
		@include xl{
			padding-left: 20px;
			padding-right: 20px;
		}
		@include lg{
			width: auto;
			order: 2;
			font-size: 24px;
			line-height: 29px;
			font-weight: 400;
			width: 246px;
			padding: 0;
			margin-left: 25px;
		}
		@include sm{
			margin-top: 23px;
			margin-left: 0;
			text-align: center;
		}
		@include xs{
			text-align: left;
		}
	}
	.input_wrap{
		padding: 28px;
		border-left: 1px solid #BDBDBD;
		border-right: 1px solid #BDBDBD;
		width: 33%;
		@include xxl{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30%;
		}
		@include lg{
			width: auto;
			border-left: none;
			border-right: none;
			border: 1px solid #BDBDBD;
			width: 300px;
			height: 88px;
			order: 1;
			padding-left: 0;
			justify-content: flex-start;
			padding-right: 0px;
		}
	}
	.input{
		background: #FFFFFF;
		position: relative;
		@include lg{
			width: 100%;
		}
		input{
			width: 100%;
			height: 100%;
			border: none;
			background: transparent;
			padding-top: 29px;
			padding-bottom: 14px;
			padding-left: 13px;
			padding-right: 13px;
			font-size: 18px;
			line-height: 21px;
			@include lg{
				padding-left: 22px;
			}
			&::placeholder{
				color: #BDBDBD;
			}
		}
		.placeholder{
			position: absolute;
			top: 5px;
			left: 13px;
			pointer-events: none;
			text-transform: uppercase;
			@include lg{
				left: 22px;
			}
		}
	}
	.button_wrap{
		width: 29%;
		padding: 28px 0;
		@include xxl{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 24%;
		}
		@include lg{
			width: auto;
			order: 3;
			padding-bottom: 0;
		}
		@include md{
			width: 100%;
			justify-content: flex-start;
		}
		@include sm{
			justify-content: center;
		}
		@include xs{
			padding-top: 29px;
			padding-bottom: 0;
		}
	}
	.button{
		@include xs{
			max-width: 100%;
			padding-left: 85px;
			padding-right: 81px;
		}
	}
}