.title_block{
	padding-top: 16px;
	padding-bottom: 16px;
	@include md{
		display: none;
	}
	.main_container{
		display: flex;
	}
	.avatar{
		font-family: $font3;
		font-size: 22px;
		line-height: 27px;
		color: #FFFFFF;
		width: 54px;
		height: 54px;
		background: #005FF9;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex-shrink: 0;
	}
	.title{
		font-size: 22px;
		line-height: 26px;
		color: #000000;
		font-weight: normal;
	}
	.email{
		font-weight: bold;
		font-size: 13px;
		line-height: 16px;
		color: #000000;
		margin-top: 10px;
	}
	.date{
		font-size: 13px;
		line-height: 16px;
		color: #828282;
	}
	.actionss{
		font-size: 13px;
		line-height: 16px;
		color: #4F4F4F;
		display: flex;
		justify-content: flex-end;
		.add{
			display: inline-block;
			cursor: pointer;
			text-decoration: underline;
		}
		.unsub{
			margin-left: 32px;
			display: inline-block;
			cursor: pointer;
			text-decoration: underline;
		}
	}
	.last-str{
		display: flex;
		justify-content: space-between;
		margin-top: 7px;
	}
	.info{
		width: 100%;
		margin-left: 34px;
	}
}